
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';

import SeriesModule from '@/stores/modules/Series';
import NevronImageField from '@/components/NevronImageField.vue';
// @ts-ignore
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import AttachImage from '@/modules/Media/Attach.vue';
import NevronAvailabilityOptions from "@/components/NevronAvailabilityOptions.vue";
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";
import {translate} from "@/helpers/functions";

@Component({
  components: {
    NevronNewSourcePopup,
    NevronAvailabilityOptions,
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    MultiSelect,
    NevronInput,
    NevronImageField,
    NevronAdvanceTextBox,
    AttachImage,
  },
})
export default class EpisodeDetails extends Vue {
  @Prop()
  module!: SeriesModule;
  @Prop()
  id!: any;
  @Prop()
  seriesId!: any;
  parentTye: string = 'series';
  sourceToEdit: IEpisodeSource | null = null;
  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] = [];
  authorizationTypes: IAuthorizationType[] = [];
  // @ts-ignore
  episodes: IEpisodes = {
    id: null,
    active: true,
    isTrailer: false,
    sort: 0,
    name: '',
    shortDescription: '',
    fullDescription: '',
    image: null,
    imageId: null,
    releaseDate: '',
    price: '0',
    rating: '',
    duration: '',
    moduleId: null,
    parentId: null,
  };
  sourcesAlert: any = {
    result: null,
    message: '',
  };
  categories: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  imageUrl: any = '';
  autocompleteCatItems: any[] = [];
  catTag: string = '';
  itemName: string = '';
  imageSource: any = '';

  /**
   * Delete the Episode from this season
   */
  deleteEpisode() {
    return this.module.deleteEpisode(this.seriesId, this.episodes?.id!)
      .then((response: any) => {
        this.$router.go(-1);
      })
      .catch((error: any) => {
        console.error(error);
      });
  }

  checkStreamUrl() {
    this.sourceToEdit!.source = '';
  }

  openMediaLibrary() {
    // @ts-ignore;
    this.$refs.updateSourceUrl.$children[0].open();
  }

  findObjectByKey(array: any, keyValue: string | null | undefined) {
    return array.find(function (element: any) {
      return element.key === keyValue;
    });
  }

  attach(a: any) {
    // @ts-ignore
    this.application.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  mediaSource(source: any) {
    this.imageSource = '';
    this.imageSource = source.result.imageUrl;
  }

  updateSourceUrl(source: any) {
    this.sourceToEdit!.source = source.result.imageUrl;
  }

  saveAndReload() {
    return axiosInstance.put(`series/${this.module.id}/${this.parentTye}/${this.$route.params.seriesId}/episodes/${this.episodes.id}`, this.episodes)
      .then((response) => {
        if (response.data.responseCode === 200) {
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.episodes.name), 'successfully updated');
        } else if (response.data.responseCode === 201) {
          // @ts-ignore
          showToaster('danger', '', response.data.error.message, response.data.code);
        } else {
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.episodes.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.error(error);
      });
  }

  selectedSource(source: IEpisodeSource) {
    console.log(source);
    if (source.sourceType === "" || !source.sourceType) {
      source.sourceType = 'stream-url';
    }
    // @ts-ignore
    this.sourceToEdit = source;
  }

  editExistingSources(source: any) {
    if (this.episodes && this.episodes.id) {
      return this.module.editExistingSource(this.episodes.id, source)
        .then((response) => {
          if (response.message) {
            this.sourceToEdit = null;
            this.sourcesAlert.message = response.message;
            this.sourcesAlert.result = response.result;
          } else {
            this.sourcesAlert.message = 'You have successfully edited a episode source!';
            this.sourcesAlert.result = true;
            this.sourceToEdit = null;
            this.loadPage();
          }
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  onMediaTypeClick(type: string) {
    if (type === 'media-library') {
      this.openMediaLibrary();
    } else {
      this.checkStreamUrl();
    }
  }

  deleteSources(id: any, es: any) {
    // @ts-ignore
    if (this.episodes.sources && confirm('Do you really want to delete this Source?')) {
      return this.module.deleteSource(Number(this.$route.params.id), id)
        .then(() => {
          this.loadPage();
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  pushBack() {
    this.$router.push({name: 'series.items', params: {moduleId: this.$route.params.moduleId}});
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.loadPage();
    this.getSourceData();
    this.itemName = this.module.itemsName;
  }

  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }
    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {
      this.deviceTypes = sourceTypes;
      this.sourceTypes = videoSourceTypes;
      this.authorizationTypes = authorizationTypes;
      // @ts-ignore
  bus().$emit('loadSourcePopup', {
        deviceTypes: sourceTypes,
        videoSourceTypes: videoSourceTypes,
        authorizationTypes: authorizationTypes
      });
    });
  }

  @Watch('$route.params.id', {immediate: true})
  loadPage() {
    this.getPage(Number(this.$route.params.id));
    this.contentUrl = '';
  }

  getPage(id: number) {
    return this.module.getEpisodesDetails(id, Number(this.$route.params.seasonsId) ?
      Number(this.$route.params.seasonsId) : Number(this.$route.params.seriesId), this.parentTye)
      .then((response) => {
        this.episodes = response;
      })
      .catch((error) => {
        console.error((error));
      });
  }

  getAutocompleteCategories(text: string = '') {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.getAutocompleteCategories(this.searchQuery, this.source.token)
          .then((response) => {
            // console.log('auto complete response', response);
            this.categories = [];
            for (const index in response) {
              if (response[index]) {
                this.categories.push(Vue.prototype.translate(response[index].name));
              }
            }
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.source.cancel();
    }
  }

  imageSelected(image: IMediaItem) {
    if (this.episodes) {
      this.episodes.imageId = (image) ? image.id : null;
    }
  }

  cancelEdit() {
    this.sourceToEdit = null;
  }

  async createNewSource(sources: IContentSource[]) {

    for (const source of sources) {
      try {
        let videoSource = source as IEpisodeSource
        videoSource.sourceableType = 'App\\Episode';
        const response = await this.module.createNewSource(+this.$route.params.id, videoSource);
        console.log(response)
        if (response) {
          showToaster('success', '', response.data)
        }
      } catch (e) {
        console.error(e);
      }
    }
    this.loadPage();
  }

  async editExistingSource(source: any) {
    try {
      let videoSource = source as IEpisodeSource
      videoSource.sourceableType = 'App\\Episode';
      const response = await this.module.editExistingSource(+this.$route.params.id, videoSource);
      if (response) {
        showToaster('success', '', 'You have sucessfully edited a source')
      }
      this.sourceToEdit = null;
    } catch (e) {
      console.error(e);
    }

    this.loadPage();
  }
}
