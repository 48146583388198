
import { Vue, Component, Prop } from 'vue-property-decorator';
import VueApexCharts from 'vue-apexcharts';

@Component({
  components: {
    VueApexCharts
  }
})
export default class PortalVisitorsStatistics extends Vue {
    @Prop({ default: true }) title !: string;

    @Prop({ default: true }) portalVisitorStatisticsData !: any;
    @Prop({ default: true }) portalVisitorButtonData !: any;
    @Prop({ default: true }) chartKey !: any;


    dropdownOpen: boolean = false;

    handleSelect(value: any) {
        this.$emit('handlePortalVisitorSelect', value);
    }
    
}
