
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import MultiSelect from 'vue-multiselect';
import NevronImageField from '@/components/NevronImageField.vue';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import Axios from 'axios';
import Editor from '@tinymce/tinymce-vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import session from '@/stores/Session';
import axiosInstance from '@/helpers/axios';
import NevronInput from '@/components/NevronInput.vue';

import SeriesModule from '@/stores/modules/Series';
import CreateSeasons from '../../../modules/Series/List/Seasons/Create.vue';
import CreateTrailor from '../../../modules/Series/List/Seasons/Episodes/create.vue';
import AttachCrew from './attachCrew.vue';
import router from '@/router';
import {showToaster} from '@/components/DashboardComposer/helpers';
import TablePopupModal from '@/components/NevronTablePopupModal.vue';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import NevronAvailabilityOptions from "@/components/NevronAvailabilityOptions.vue";
import {goToModuleIndex} from "@/helpers/routing";

@Component({
  components: {
    NevronAvailabilityOptions,
    virtualList,
    Editor,
    NevronHeader,
    NevronFooter,
    Skeleton,
    // ClassicEditor,
    MultiSelect,
    NevronInput,

    CreateSeasons,
    CreateTrailor,
    AttachCrew,
    NevronImageField,
    TablePopupModal,
    NevronAdvanceTextBox,
  },
})
export default class SeriesDetails extends Vue {
  @Prop()
  module!: SeriesModule;

  parentTye: string = 'series';
  episodeType: string = 'trailer';
  crewMetaKey: string = 'crewEdit';
  castMetaKey: string = 'castEdit';
  stores = stores;

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  // @ts-ignore
  series: ISeries | null = null;
  allCategories: ICategories[] = [];
  allPackages: IPackage[] = [];
  seasons: any[] = [];
  trailors: any[] = [];
  categoryTags: any[] = [];

  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;

  autocompleteItems: any[] = [];
  selectedFolderId: any = 1;
  contentUrl: any = '';
  catTag: string = '';

  itemName: string = '';
  editItem: any = '';
  pivotValue: any = '';
  staffEmail: IItemSetting = {
    moduleId: this.module.id,
    itemId: this.series?.id,
    key: 'staff_email',
    value: '',
    isTransalatable: false,
  };


  @Watch('$route', {immediate: true, deep: true})
  changePage() {
    this.loadPage();
  }

  /**
   * Called when changes need to re-render
   */
  mounted() {
    // this.episodeType = 'trailer';
    this.loadPage();
    // this.getAutocompleteCategories();
    this.itemName = this.module.itemsName;
  }

  loadPage() {
    this.getSeries(Number(this.$route.params.id));
    this.getPackages();
    this.getCategories();
    this.contentUrl = '';
  }

  loadFullPage() {
    this.loadPage();
  }

  pushBack() {
    this.$router.push({
      name: 'series', params: {moduleId: this.$route.params.moduleId},
      query: {
        page: this.$route.query.lastpage,
        perpage: this.$route.query.perpage,
      },
    });
  }

  imageSelected(image: IMediaItem) {
    if (this.series) {
      this.series.imageId = (image) ? image.id : null;
    }
  }

  backgroundImageSelected(image: IMediaItem) {
    if (this.series) {
      this.series.backgroundImageId = (image) ? image.id : null;
    }
  }

  detachCategory(selectedCategory: any) {
    if (this.series) {
      return this.module.removeCategory(selectedCategory, Number(this.$route.params.id))
        .then((response) => {
          console.log(response);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  /**
   * Delete the Series from this account
   */
  deleteSeries() {
    return this.module.deleteSeries(this.series?.id!)
      .then((response: any) => {
        if(!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', Vue.prototype.translate(this.series?.name), Vue.prototype.translate('successfully-deleted'));
        //@ts-ignore
        goToModuleIndex(this.module, {tab: 'list'});
      })
      .catch((error: any) => {
        showToaster('danger', '', error);
      });
  }

  detachCrew(selectedCrew: any, seriesId: number, e: any) {
    e.preventDefault();
    if (this.series && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCrew(selectedCrew, seriesId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachCast(selectedCast: any, seriesId: number, e: any) {
    e.preventDefault();
    if (this.series && confirm('Do you really want to detach this Crew?')) {
      return this.module.detachCast(selectedCast, seriesId)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachTrailer(id: any, seriesId: number, e: any) {
    e.preventDefault();
    if (this.series && confirm('Do you really want to delete this Trailor?')) {
      return this.module.detachTrailer(this.parentTye, this.series.id, id)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  detachSeasons(id: any, seriesId: number, e: any) {
    e.preventDefault();
    if (this.series && confirm('Do you really want to delete this Season?')) {
      return this.module.detachSeason(this.series.id!, id)
        .then((response: any) => {
          this.loadPage();

        })
        .catch((error: any) => {
          console.log(error);
        });
    }
  }

  openEpisodeModel() {
    this.episodeType = 'trailer';
    localStorage.setItem('episodeTypes', 'trailer');
    // @ts-ignore
    this.$refs.createEpisodes.$children[0].open();
  }

  attach(a: any) {
    // @ts-ignore
    this.application.tags.push({text: a.tag.text});
    this.catTag = '';
  }

  saveAndReload(item: ISeries) {
    const settings = [];
    settings.push(this.staffEmail);
    item.itemSettings = settings;

    return this.module.updateItem(item.id!, item)
      .then(async (response) => {
        if (response.responseCode === 200) {
          await this.getSeries(Number(this.$route.params.id));
          // @ts-ignore
          showToaster('success', Vue.prototype.translate(this.series.name), 'successfully updated');
        }else if (response.responseCode === 201) {
          // @ts-ignore
          showToaster('danger', '', response.error.message, response.code);
        } else {
          // @ts-ignore
          showToaster('danger', Vue.prototype.translate(this.series.name), 'Fail to update', response.code);
        }
        this.loadPage();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deletePage() {
    return;
  }

  @Watch('module', {immediate: true})
  LoadpageDetail() {
    this.episodeType = 'trailer';
    this.loadPage();
  }

  getSeries(id: number) {
    return this.module.getSeriesDetails(id)
      .then((response) => {
        this.series = response;
        this.series!.categories.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));
        this.series?.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));

          //settings
          this.itemSetting(response.itemSettings);
      })
      .catch((error) => {
        console.log((error));
      });
  }
  itemSetting(settings: any) {
    for (const index of settings) {
      if(index.key == 'staff_email') {
        this.staffEmail.value = index.value;
      }
    }
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  getCategories() {
    this.module.getAllCategories()
      .then((response: any) => {
        if(response && response.data) {
          this.allCategories = response.data;
          this.allCategories.forEach((category: ICategories) =>
            category.name = Vue.prototype.translate(category.name));
        }
      });
  }

  getTrailor(id: number) {
    return axiosInstance.get(`series/${this.module.id}/${this.parentTye}/${this.$route.params.id}/episodes`)
      .then((response) => {
        this.trailors = response.data.data;
      })
      .catch((error) => {
        console.log((error));
      });
  }

  addCategory(selectedCategory: any) {
    return this.module.addCategory(selectedCategory, Number(this.$route.params.id))
      .then((response) => {
        console.log('response', response);

      })
      .catch((e) => {
        console.log(e);
      });
  }

  updateCrewPosition(item: any) {
    return this.module.updateCrew(this.module.id, this.series!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  updateCastPosition(item: any) {
    return this.module.updateCast(this.module.id, this.series!.id!, this.pivotValue.id, item)
      .then((response) => {
        if (response.responseCode === 200) {
          showToaster('success', Vue.prototype.translate(this.pivotValue.name), 'Successfully updated');
        } else {
          showToaster('danger', Vue.prototype.translate(this.pivotValue.name), 'Fail to update');
        }
        this.loadPage();
      });
  }

  openCrewPositionPopup(item: any) {
    this.editItem = item.pivot.role;
    this.pivotValue = item;
    // @ts-ignore
    this.$refs.crewEditPopup.$children[0].open();
  }

  openCastPositionPopup(item: any) {
    this.editItem = item.pivot.role;
    this.pivotValue = item;
    // @ts-ignore
    this.$refs.castEditPopup.$children[0].open();
  }
}
