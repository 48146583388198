
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import virtualList from 'vue-virtual-scroll-list';
import stores from '@/stores';
import VODModule from '@/stores/modules/VODs';
import Axios from 'axios';
import Edit from '@/modules/VOD/Movies/Edit.vue';
import VueTagsInput from '@johmun/vue-tags-input';
import Editor from '@tinymce/tinymce-vue';
import Gallery from '@/modules/VOD/Movies/ImageGallery.vue';
import NevronHeader from '@/components/ShowNevronComponents/NevronHeader.vue';
import NevronFooter from '@/components/ShowNevronComponents/NevronFooter.vue';
import Skeleton from '@/modules/Skeleton.vue';
import MultiSelect from 'vue-multiselect';
import DynamicTranslation from '@/modules/Translation/DynamicContent.vue';
import NevronInput from '@/components/NevronInput.vue';
import {Packages} from '@/stores/Package';
import NevronImageField from '@/components/NevronImageField.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import NevronAdvanceTextBox from '@/components/NevronAdvanceTextBox.vue';
import {load} from "@tinymce/tinymce-vue/lib/es2015/ScriptLoader";
import {goToModuleIndex} from "@/helpers/routing";
import {validateForm} from "@/helpers/functions";
import NevronNewSourcePopup from "@/components/NevronNewSourcePopup.vue";

@Component({
  methods: {load},
  components: {
    NevronNewSourcePopup,
    virtualList,
    Edit,
    VueTagsInput,
    Editor,
    Gallery,
    NevronHeader,
    NevronFooter,
    Skeleton,
    MultiSelect,
    DynamicTranslation,
    NevronInput,
    NevronImageField,
    NevronAdvanceTextBox,
  },
})

export default class VODDetails extends Vue {
  @Prop()
  module!: VODModule;

  searchQuery: string = '';
  indexList: number[] = [];
  allChecked: boolean = false;
  movie: IVIDEO | null = null;
  searchInProgress: boolean = false;
  CancelToken: any = Axios.CancelToken;
  source: any;
  attachableContent: any = null;
  tag: any = '';
  tags: any[] = [];
  autocompleteItems: any[] = [];
  sourceToEdit: IVideoSource | null = null;
  galleryOpen: boolean = false;
  fileExplorerOpen: boolean = false;
  allCategories: ICategories[] = [];
  allPackages: Packages[] = [];
  contentUrl: any = '';
  deviceTypes: IDeviceType[] = [];
  sourceTypes: ISourcesType[] = [];
  authorizationTypes: IAuthorizationType[] = [];
  referenceKey: any = '';
  referenceText: any = '';
  sourcesAlert: any = {
    result: null,
    message: '',
  };

  /**
   * Called when changes need to re-render
   */
  mounted() {
    this.getSourceData();
    this.loadPage();
  }

  openTranslator(key: string, text: Text) {
    this.referenceKey = key;
    this.referenceText = text;
    // @ts-ignore
    this.$refs.translation.$children[0].open();
  }

  imageSelected(image: IMediaItem) {
    if (this.movie) {
      this.movie.imageId = (image) ? image.id : null;
    }
  }

  submitForm() {
    if (validateForm('update-vod-form')) {
      this.save();
    }
  }

  save() {
    if (this.movie) {
      return this.module.updateItem(this.movie.id, this.movie)
        .then(async (response) => {
          if (response.responseCode === 200) {
            await this.getVOD(Number(this.$route.params.id));
            // @ts-ignore
            showToaster('success', Vue.prototype.translate(this.movie.name), 'successfully updated');
          } else {
            // @ts-ignore
            showToaster('danger', Vue.prototype.translate(this.movie.name), 'Fail to update', response.code);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  closeGallery(status: boolean) {
    this.galleryOpen = status;
    // @ts-ignore
    this.$refs.gallery.$children[0].close();
  }

  handleCloseImageAttach() {
    // @ts-ignore
    this.$refs.attach.$children[0].close();
    this.fileExplorerOpen = false;
  }

  galleryDetach(element: any, e: any = null) {
    if (e) {
      e.preventDefault();
    }
    if (this.movie && this.movie.id) {
      return this.module.detachGallery(this.movie.id, element.id)
        .then((response) => {
          // @ts-ignore
          this.movie.gallery.splice(element.i, 1);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  galleryAttachNew(id: number) {
    if (this.movie && this.movie.id) {
      return this.module.attachGallery(this.movie.id, id)
        .then((response) => {
          // @ts-ignore
          this.movie.gallery.push(response);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  getSourceData(e: any = null) {
    if (e) {
      e.preventDefault();
    }
    Promise.all([
      stores.modules.getSourceTypes(),
      stores.modules.getVideoSourceTypes(),
      stores.modules.getAuthorizationTypes()
    ]).then(([sourceTypes, videoSourceTypes, authorizationTypes]) => {
      this.deviceTypes = sourceTypes;
      this.sourceTypes = videoSourceTypes;
      this.authorizationTypes = authorizationTypes;
      // @ts-ignore
      bus().$emit('loadSourcePopup', {
        deviceTypes: sourceTypes,
        videoSourceTypes: videoSourceTypes,
        authorizationTypes: authorizationTypes
      });
    });
  }

  galleryAttach(id: number, newImage: boolean) {
    if (this.movie) {
      this.movie.imageId = id;
      if (this.movie && this.movie.id) {
        return this.module.attachGallery(this.movie.id, id)
          .then((response) => {
            // @ts-ignore
            this.movie.gallery.push(response);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  }

  openGallery(e: any) {
    e.preventDefault();
    this.galleryOpen = true;
    // @ts-ignore
    this.$refs.gallery.$children[0].open();
  }

  openSources(e: any) {
    e.preventDefault();
    // @ts-ignore
    this.$refs.source.$children[0].open();
  }

  deleteSource(id: any) {
    // @ts-ignore
    if (confirm('Do you really want to delete this Source?')) {
      return this.module.deleteSource(Number(this.$route.params.id), id)
        .then(() => {
          this.loadPage();
        })
        .catch((e: any) => {
          console.error(e);
        });
    }
  }

  updateCheck() {
    if (this.movie) {
      if (this.indexList.length === this.movie.sources.length) {
        this.allChecked = true;
      } else {
        this.allChecked = false;
      }
    }
  }

  async createNewSource(sources: IContentSource[]) {

    if (!this.movie || !this.movie.id) {
      return;
    }

    for (const source of sources) {
      try {
        let videoSource = source as IVideoSource
        videoSource.movieId = this.movie.id;
        videoSource.sourceableType = 'App\\Movie';

        const response = await this.module.createNewSource(this.movie.id, videoSource);
        if (response) {
          showToaster('success', '', response.data)
        }
      } catch (e) {
        console.error(e);
      }
    }

    // @ts-ignore
    await this.getVOD(this.movie.id);
  }

  editExistingSource(source: any) {
    if (this.movie && this.movie.id) {
      return this.module.editExistingSource(this.movie.id, source)
        .then(() => {
          console.log('You have succsesfully edited a channel source!');
          this.sourceToEdit = null;
          // @ts-ignore
          this.getVOD(this.movie.id);
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  }

  selectedSource(source: IVideoSource) {
    this.sourceToEdit = source;
  }

  getAutocompleteCategories(text: string) {
    this.CancelToken = Axios.CancelToken;
    this.source = this.CancelToken.source();
    if (!this.searchInProgress) {
      this.searchInProgress = true;
      setTimeout(() => {
        return this.module.getAutocompleteCategories(this.searchQuery, this.source.token)
          .then((response) => {
            this.autocompleteItems = [];

            for (const iterator of response) {
              this.autocompleteItems.push(iterator.name);
            }
            this.$router.push({query: {query: this.searchQuery}});
            this.searchInProgress = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 500);
    } else {
      this.source.cancel();
    }
  }

  get filteredItems() {
    return this.autocompleteItems.filter((i) => {
      return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
    });
  }

  saveAndReload(v: IVIDEO) {
    if (!this.galleryOpen) {
      // @ts-ignore
      v.realId = v.imageId; // this is necesary becouse if in one instance you get the actual id from image, whereas you can get the the id from where the actual image is connected in folder
    }
    return this.module.editVODMovie(v)
      .then((response) => {
        this.movie = response;
        return this.module.getSelectedVODMovie(response.id);
      })
      .then((response) => {
        this.movie = response;
        this.tags = [];
        for (const iterator of response.categories) {
          this.tags.push(iterator.name);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getImages() {
    // @ts-ignore
    this.$refs.attach.$children[0].open();
  }

  getVOD(id: number) {
    return this.module.getSelectedVODMovie(id)
      .then((response) => {
        this.movie = response;
        this.movie!.categories?.forEach((category: ICategories) =>
          category.name = Vue.prototype.translate(category.name));

        this.movie?.packages?.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  @Watch('$route')
  onUrlChange() {
    this.loadPage();
  }

  loadPage() {
    this.getSourceData();
    this.getVOD(Number(this.$route.params.id))
      .then((res) => {
        this.module.getAllCategories()
          .then((response: any) => {
            this.allCategories = response.data;
            this.allCategories.forEach((category: ICategories) =>
              category.name = Vue.prototype.translate(category.name));
          });
      });
    this.getPackages();
  }

  getPackages() {
    stores.Package.getAllPackages()
      .then((response: any) => {
        this.allPackages = response;
        this.allPackages.forEach((item: IPackage) =>
          item.name = Vue.prototype.translate(item.name));
      });
  }

  cancelEditSource() {
    this.sourceToEdit = null;
  }

  deleteMovie() {
    if (this.movie && this.movie.id) {
      this.module.deleteItem(this.movie.id)
        .then((response: any) => {
          if (!response.success) {
            throw new Error(response.error.message);
          }
          showToaster('success', Vue.prototype.translate(this.movie?.name), Vue.prototype.translate('successfully-deleted'));
          // @ts-ignore
          goToModuleIndex(this.module, {tab: 'list'});
        })
        .catch((error) => {
          showToaster('danger', '', error);
        });
    }
  }

  cancelEdit() {
    this.sourceToEdit = null;
  }
}
