
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {modulesMetaData} from '@/helpers/constants';
import PerPageDropdown from '@/components/PerPageDropdown.vue';
import vPagination from '@/components/VuePlainPagination.vue';
import ListNevronHeader from '@/components/ListNevronComponents/ListNevronHeader.vue';
import Skeleton from '@/modules/Skeleton.vue';
import NevronEmpty from '@/components/ListNevronComponents/EmptyNevronHeader.vue';
import NevronSearch from '@/components/NevronSearch.vue';
import NevronSearchNoResults from '@/components/NevronSearchNoResults.vue';
import _ from 'lodash';
import {bus, exportData, importData, translate, translatedDisplayName} from '@/helpers/functions';
import TableGeneric from '@/components/NevronTableGeneric.vue';
import {showToaster} from '@/components/DashboardComposer/helpers';
import DynamicContent from '@/modules/Translation/DynamicContent.vue';
import NevronCopyMovePopupModal from '@/components/NevronCopyMovePopupModal.vue';
import axios from "axios";
// @ts-ignore
import config from '../../../config/config.js';
import TableModalGeneric from '@/components/NevronTableModalGeneric.vue';

import stores from '@/stores';

@Component({
  components: {
    ListNevronHeader,
    Skeleton,
    NevronEmpty,
    NevronSearch,
    NevronSearchNoResults,
    PerPageDropdown,
    vPagination,
    TableGeneric,
    DynamicContent,
    NevronCopyMovePopupModal,
    TableModalGeneric,
  },
})

export default class IndexGeneric extends Vue {
  @Prop()
  instance: any;
  @Prop({type: Boolean, default: true})
  showPageTitle!: boolean;

  response: any = null;
  items: IItem[] = [];
  openSpecificPage: boolean = false;

  selectedArray: number[] = [];
  allChecked = false;
  resetSelected = false;

  loading = true;
  file: any = '';
  uploadResponse: any = '';
  firstLoad = true;
  perpage = 20;

  search: any = {
    query: '',
    current: '',
  };
  ids: any = [];
  moveCopy: any = '';
  checkModule: any = '';
  recipientsList: any = '';

  createComponent: any = null;
  showComponent: any = null;
  selectedItem: IItem | null = null;
  showDynamicModule: boolean = false;
  stores = stores;

  localKey: any = '';
  localText: any = '';
  referenceKey: any = '';
  index: any = '';
  list: any = '';
  btnDisable: boolean = false;
  searchItems = _.debounce(() => {
    this.fetchData(1);
  }, 400); // fetches data with debounce (ms)

  openCustomerPopup(recipientsList: any) {
    this.recipientsList = recipientsList;
    // @ts-ignore
    this.$refs.addRecipients.$children[0].open();
  }

  openCreatePage() {

    this.openSpecificPage = false;
    // @ts-ignore
    this.$refs.create.$children[0].open()

  }

  get pageTitle() {
    if (this.instance.name) {
      return this.instance.name;
    } else {
      return this.instanceMeta.displayName;
    }
  }

  get instanceMeta(): any {
    // @ts-ignore
    return modulesMetaData[this.instance.routeName];
  }


  get fields() {
    return this.instanceMeta.fields;
  }

  copySelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    this.moveCopy = 'copy';
    this.ids = this.selectedArray.map((el: any) => el.id);
    // @ts-ignore
    this.$refs.copymove.$children[0].open();

  }

  moveSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    this.moveCopy = 'move';
    this.ids = this.selectedArray.map((el: any) => el.id);
    // @ts-ignore
    this.$refs.copymove.$children[0].open();

  }

  mounted() {

bus().$on('recipient', () => {
      this.openSpecificPage = true;
      //@ts-ignore
      this.$refs.create.$children[0].open();
    });
    // set search query from URL
    if (this.$route.query.search) {
      this.search.query = this.$route.query.search;
      this.search.current = this.$route.query.search;
    }
    // set perpage from URL
    if (this.$route.query.perpage) {
      this.perpage = Number(this.$route.query.perpage);
    }
    this.moduleChanged();

    bus().$on('openTranslator', (localText: any, localKey: any, referenceKey: any) => {
      this.localText = localText;
      this.localKey = localKey;
      this.referenceKey = referenceKey;
      // @ts-ignore
      this.$refs.translator.$children[0].open();
    });
    this.showDynamicModule = this.instanceMeta['dynamicModule'];

  }

  @Watch('instance')
  moduleChanged() {
    this.index = (this.firstLoad && this.$route.query.page) ? Number(this.$route.query.page) : 1;
    this.perpage = 20;
    this.search.current = '';
    this.firstLoad = true;
    this.fetchData(this.index);
    if (!this.instanceMeta.dynamicModule) {
      Vue.prototype.WebsiteTitle.setModuleName(this.pageTitle);
    }
    this.checkModule = this.instance.routeName;
    if (this.checkModule === 'customers') {
      this.createComponent = () => import(`@/${this.instanceMeta.checkIn}.vue`);
    } else {
      if (this.instanceMeta.createComponent) {
        this.createComponent = () => import(`@/${this.instanceMeta.createComponent}.vue`);
      } else {
        this.createComponent = null;
      }
    }

    if (this.instanceMeta.showComponent) {
      this.showComponent = () => import(`@/${this.instanceMeta.showComponent}.vue`);
    } else {
      this.showComponent = null;
    }
  }

  fetchData(index: number | null) {
    this.btnDisable = true;
    if (this.firstLoad) {
      this.loading = true;
    }

    // Define the parameters to pass to the fetchData method
    const fetchDataParams = [index, this.search.query, this.perpage];
    // If the 'tab' parameter is present, add it to the parameters
    if (this.instanceMeta.queryTabParam) {
      const tab = this.$route.query.tab;
      fetchDataParams.push(tab ? tab : null);
    }

    return this.instance.fetchData(...fetchDataParams)
      .then((response: any) => {
        this.response = response;
        this.items = this.response.data;
        this.search.current = this.search.query;
        this.btnDisable = false;
        const availableIds = this.items.map((el: IItem) => el.id);
        this.selectedArray = this.selectedArray.filter((el: any) => availableIds.includes(el.id));
        if (!response) {
          showToaster('danger', '', 'Failed to fetch Data', response.code);
        }

        if (this.items.length === 0 && this.response.currentPage > 1) {
          this.fetchData(this.response.currentPage - 1);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        this.loading = false;
        this.firstLoad = false;
      });
  }

  deleteSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }

    const ids = this.selectedArray.map((el: any) => el.id);
    this.instance.deleteItems(ids, this.selectedArray)
      .then((response: any) => {
        if (!response.success) {
          if (response.responseCode == 500) {
            showToaster('danger', translate('error-in-deleting-multiple-items'), response.error.message);
          } else {
            showToaster('danger', '', translate(response.error.message), response.responseCode);
          }
        } else {
          this.resetSelected = !this.resetSelected;
          showToaster('success', '', Vue.prototype.translate('successfully-deleted'));
          if (this.instance.routeName === 'modules') {
            // @ts-ignore
            this.$router.go();
          }
        }
      }).catch((e: any) => {
      showToaster('danger', translate('error-in-deleting-multiple-items'), Vue.prototype.translate(e));
    })
      .finally(() => {
        this.refresh();
        this.selectedArray = [];
      });
  }

  checkOutSelected() {
    if (this.selectedArray.length === 0) {
      return;
    }
    const instance = axios.create({
      baseURL: config.apiUrl,
      timeout: config.apiTimeout,
    });
    const items = this.selectedArray.filter((el: any) => {
      if (el.stays.status === 'Active' || el.stays.status === 'active') {
        return this.selectedArray.map((item: any) => item.id);
      }
    });

    if (items === []) {
      showToaster('danger', ' ', 'Only Active stay can be checkout..!!', 400);
    }
    const guests = items.map(items => {
      // @ts-ignore
      return {"request": "out", "guest_id": items.id, 'checkin_type': 'mn-checkin'};
    });
    const array: any = {
      "isDatabaseResync": true,
      "guests": []
    };
    guests.forEach(guest => {
      array.guests.push(guest);
    });

    const currentPaths = window.location.pathname.split('/').filter((el) => el.length > 0);
    instance.post(currentPaths[0], array)
      .then((response: any) => {
        if (!response.success) {
          throw new Error(response.error.message);
        }
        showToaster('success', ' ', 'check Out is completed..!!', 200);
      })
      .catch(error => {
        if (error.response.status !== 200) {
          showToaster('danger', ' ', error.response.data.message, error.response.status);
        }
      });
    4
  }

  openItem(item: IItem) {
    if (this.showComponent) {
      this.selectedItem = item;
      // @ts-ignore
      this.$refs.show.$children[0].open();
      return;
    }
    const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
    this.$router.push({
      name: `${this.instance.routeName}.${showPath}`,
      params: {id: String(item.id), key: String(item.key)},
      query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)},
    });
  }

  isItemLink(fieldIndex: number) {
    const hasImage = this.fields[0].type === 'image';
    return (!hasImage && fieldIndex === 0) || (hasImage && fieldIndex === 1);
  }

  deleteItem(item: any) {
    this.instance.deleteItem(item.id, item)
      .then((response: any) => {
        this.refresh();
        if (!response.success) {
          if (response.responseCode == 500) {
            showToaster('danger', '', response.error.message);
          } else {
            showToaster('danger', '', translate(response.error.message), response.responseCode);
          }
        } else {
          showToaster('success', translatedDisplayName(item), translate('successfully-deleted'));
          if (this.instance.routeName === 'modules') {
            this.$router.go(0);
          }
        }
      })
      .catch((error: any) => {
        showToaster('danger', '', error);
      })
      .finally(() => {
        this.selectedArray = this.selectedArray.filter((el: any) => el.id !== item.id);
      })
  }

  updateRadioValue(item: IItem, fieldName: string) {
    const data = {id: item.id, name: item.name};
    // @ts-ignore
    data[fieldName] = true;
    this.instance.updateItem(item.id, data).finally(() => {
      this.refresh();
    })
  }

  refresh() {
    this.fetchData(this.index);
  }

  redirect(value: any) {
    if (this.instance.routeName === 'clock' || this.instance.routeName === 'locations') {
      this.refresh();
    } else {
      const showPath = (this.instanceMeta.dynamicModule) ? 'items.show' : 'show';
      this.$router.push({
        name: `${this.instance.routeName}.${showPath}`,
        params: {id: String(value)},
        query: {lastpage: String(this.response.currentPage), perpage: String(this.perpage)},
      });
    }
  }

  @Watch('selectedArray')
  emitSelectedArray() {
    this.$emit('input', this.selectedArray);
  }

  @Watch('response.currentPage')
  pageChange() {
    if (this.firstLoad) {
      this.firstLoad = false;
      return;
    }
    // this.search.query = this.search.current;
    this.fetchData(this.response.currentPage);
  }

  exportSelected() {
    // @ts-ignore
    const ids = this.selectedArray.map((item) => item.id);
    exportData(this.instance.exportUrl(), ids).then((response) => {
      if (response.result === true) {
        const csv = response.data;
        this.downloadCsv(csv)
      }
    });
  }

  downloadCsv(csv: any) {
    const anchor = document.createElement('a');
    anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    anchor.target = '_blank';
    anchor.download = 'Nevron-' + this.instance.routeName + '.csv';
    anchor.click();
  }

  @Watch('uploadResponse')
  importCsv() {
    if (this.uploadResponse.length === 0) return
    let importCsv = {
      data: null,
      module_id: null,
    };
    importCsv.data = this.uploadResponse;
    importCsv.module_id = this.instance.id

    importData(this.instance.importUrl(), importCsv)
      .then((response) => {
        if (response.data && response.responseCode && response.responseCode === 200) {
          showToaster('success', '', response.data, response.responseCode);
          this.refresh();
          return
        }
        let error = response;
        if (error.response.status >= 400 && error.response.status < 500) {
          let errorMsg = error.response?.data?.error?.message || error.response?.data?.data;
          showToaster('warning', '', errorMsg, error.response.status);
          console.error(error);
          this.refresh();
        }
      }).catch((e) => {
      console.error(e)
    })

    // @ts-ignore
    this.$refs.file.value = null
    this.uploadResponse = ''
  }

  importFile(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      this.uploadResponse = e.target ? e.target.result : '';
    };
    reader.readAsText(file);
  }

  saveStatus(item: any){
    this.$emit('saveStatus',item)
  }

  showConfirmModal(item:any){
    this.$emit('showConfirmModal',item)
  }

  get shouldHideEdit() {
    return !!this.instanceMeta.options?.hideEdit;
  }

  get shouldHideActions(){
    return !!this.instanceMeta.options?.hideActions;
  }

  get shouldHideDelete() {
    return !!this.instanceMeta.options?.hideDelete;
  }

  get shouldHideImport() {
    return !!this.instanceMeta.options?.hideImport;
  }

  get shouldHideExport() {
    return !!this.instanceMeta.options?.hideExport;
  }

  get shouldShowDuplicate() {
    return !!this.instanceMeta.options?.showDuplicate;
  }

  get shouldShowFirstColumnAsLink(){
    return this.instanceMeta.options?.showFirstColumnAsLink;
  }

  openResult(item: object) {
    this.$emit('openResult', item);
  }

}
